
.container-new-landing-page{
  width: 80vw;
  min-height: 60vh;
  /* background-color: ivory; */
}

.container-landing-page-mobile{
  width: 100%;
  min-height: 60vh;
  /* background-color: ivory; */
}

/* Tagline section */
.container-tagline-section{
  width: 80vw;
  min-height: 90vh;
  background-color: ivory;
  border-radius: 10px 10px 10px 10px;
  padding: 2em;
}

.container-tagline-mobile{
  width: 100vw;
  background-color: ivory;
  /* border-radius: 10px 10px 10px 10px; */
  padding: 1.5em;
}

.title-tagline-mobile{
  position: relative;
  width: 70vw;
  font-family:'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2em;
  line-height: 1.8em;
  top:.6em;
  color: #101A24;
}

.subtitle-tagline-mobile{
  font-family:'Times New Roman', Times, serif;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 181% */
  color: #5C6574;
}

.subtitle-tagline-2-mobile{
  font-family:'Times New Roman', Times, serif;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 181% */
  color: #5C6574;
}

.image-tagline-mobile{
  position: relative;
  width: 8em;
  height: auto;
  right: 0em;
  top: 0em;
  border-radius: 10px 10px 10px 10px;
}

/* Desktop */
.title-tagline{
  font-family:'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 3em;
  line-height: 1.2em;
  color: #101A24;
}

.subtitle-tagline{
  width: 60%;
  font-family:'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.6em;
  /* or 181% */
  color: #5C6574;
}

.subtitle-tagline-2{

  font-family:'Times New Roman', Times, serif;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  color: #5C6574;
}

.image-tagline{
  width: 28em;
  height: auto;
  border-radius: 80px 30px 80px 30px;
}

.button-tagline{
  margin-top: 3em;
  width: 6em; /*120*/
  height: 2.6em; /*54*/
  background-color: #101a24;
  border-radius: 20px 5px 20px 5px;
  color: white;
  font-family: 'Inter';
  font-size: 1em;
}

.social-btn-list{
  margin-top: 3em;
  display: flex;
  flex-direction: row;
}


/* === Menu === */
.menu-section-container{
  width: 80vw;
  min-height: 90vh;
  border-radius: 10px 10px 10px 10px;
  padding: .8em;
}

.menu-section-container-mobile{
  width: 90vw;
  min-height: 70vh;
  border-radius: 10px 10px 10px 10px;
}

.menu-title{
  font-size: 2em;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: ivory;
}

.menu-title-mobile{
  font-size: 1.6em;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: ivory;
  text-align: center;
}

.menu-name{
  font-size: 1.6em;
  font-weight: bold;
  font-family:'Times New Roman', Times, serif;
  font-style: italic;
  color: ivory;
  text-transform: capitalize;
  margin-bottom: .2em;
}

.menu-description{
  font-size: 1em;
  font-weight: 400;
  color: ivory;
}

.menu-name-right{
  font-size: 1.6em;
  font-weight: bold;
  font-family:'Times New Roman', Times, serif;
  font-style: italic;
  color: ivory;
  text-transform: capitalize;
  margin-bottom: .2em;
}

.menu-sauce-section{
  width: 100vw;
  min-height: 10vh;
  padding: 2em;
  background-color: #FBFBFA;
}

/* === Location === */
.location-section-container{
  width: 80vw;
  min-height: 30vh;
  border-radius: 10px 10px 10px 10px;
  padding: 2em;
}

.location-section-container-mobile{
  width: 100vw;
  padding: .8em;
}

.mapouter{
  position:relative;
  text-align:right;
  width:600px;
  height:320px;
}

.gmap_canvas {
  overflow:hidden;
  background:none!important;
  width:600px;height:320px;}

.gmap_iframe {
  width:600px!important;
  height:320px!important;
}

.mapouter-mobile{
  width:100vw;
  height:auto;
}

.gmap_canvas-mobile {
  overflow:hidden;
  background:none!important;
  width:100%;
  height:200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* == Banner == */
.banner-background{
  width: 100vw;
  height: 22em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fffff0;
  opacity: 0.8;
  background-image: radial-gradient(#ae2129 1.05px, #fffff0 1.05px);
  background-size: 21px 21px;
}

.banner-background-mobile{
  width: 100vw;
  height: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fffff0;
  opacity: 0.8;
  background-image: radial-gradient(#ae2129 .5px, #fffff0 .5px);
  background-size: 21px 21px;
  overflow: hidden;
}

.banner-background-image{
  width: 100%;
  height: 12em;
  object-fit: cover;
  opacity: 0.7;
  position: absolute;
}

/* == Banner Button == */
.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-right: 12px;
}

.btn-mobile{
  line-height: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #ae2129;
}

.btn-one {
  color: #ae3d21;
  transition: all 0.3s;
  position: relative;
}
.btn-one span {
  transition: all 0.3s;
}
.btn-one::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(230, 27, 27, 0.5);
  border-bottom-color: rgba(230, 27, 27, 0.5);
  transform: scale(0.1, 1);
}
.btn-one:hover span {
  letter-spacing: 2px;
}
.btn-one:hover::before {
  opacity: 1; 
  transform: scale(1, 1); 
}
.btn-one::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255,255,255,0.1);
}
.btn-one:hover::after {
  opacity: 0; 
  transform: scale(0.1, 1);
}

.banner-icon{
  width: 50%;
  margin-left: .2em;
}

/* === Footer === */
/* Desktop */
.footer-logo{
  width: 5em;
  height: auto;
}

/* Desktop */
.footer-title{
  font-size: 1.2em;
  font-weight: bold;
  font-family:'Times New Roman', Times, serif;
  color: #37250f;
  /* color: ivory; */
}

.footer-subtitle{
  font-size: 1em;
  font-family:sans-serif;
  color: #37250f;
}

.footer-bottom-text{
  font-size: .8em;
  font-family:sans-serif;
  color: #37250f;
  margin-bottom: 1em;

 
}

.footer-link{
  text-decoration: none;
  font-size: 1em;
  font-family: sans-serif;

  color: #37250f;
}

.footer-social-btn-list{
  display: flex;
  flex-direction: row;
  margin-top: 6px;
}

/* Mobile */
.footer-title-mobile{
  font-size: 1em;
  font-weight: bold;
  font-family:'Times New Roman', Times, serif;
  color: #37250f;
}

.footer-subtitle-mobile{
  font-size: .8em;
  font-family:sans-serif;
  color: #37250f;
  margin-bottom: 1em;
}

.footer-bottom-text-mobile{
  width: 100%;
  font-size: .8em;
  font-family:sans-serif;
  position: relative;
  bottom: -28px;
  color: #37250f;
  text-align: center;
  padding-top: .2em;
  padding-bottom: .2em;
  background-color: white;
}

.footer-link-mobile{
  text-decoration: none;
  font-size: .9em;
  font-family: sans-serif;
  margin-top: .5em;

  color: #37250f;
}

.footer-social-btn-list-mobile{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  align-items: center;
  justify-content: center;
}

.footer-icon-element-mobile{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

